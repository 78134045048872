.App {
  text-align: center;
}

.App-logo {
  height: 100vmin;
  box-shadow: 25px 25px 50px 0 black inset, -25px -25px 50px 0 black inset;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
